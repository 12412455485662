* {
  box-sizing: border-box;
  font-family: "Cormorant SC", serif;
}

.App {
  width: 100%;
}

main {
  width: 100%;
}



.mainName {
  position: relative;
  padding: 1rem .5rem;
  font-size: 4rem;
  text-decoration: none;
  color: rgb(1, 94, 1);
  line-height: 52px;
}

.navBar Button {
  padding: 1rem 2rem;
  flex-flow: column;
}

.welcome {
  position: relative;
}

.welcome img {
  width: 100%;
  margin-top: 3.2rem;
}



.welcome figcaption {
  position: absolute;
  font-weight: bolder;
  font-size: 40px;
  text-align: center;
  width: 90%;
}


.mainServicePics {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: 1550px;
}

.mainServicePics Button {
  width: 390px;
}



.mainServicePics figcaption {
  text-align: center;
  position: absolute;
  font-weight: bolder;
  box-shadow: 0 0  0 rgb(0, 0, 0);
  border-radius: 1rem;
  color: azure;
  align-items: center;
  text-align: center;
  padding-top: 62%;
}

.mainServiceContent {
  position: absolute;
  color: azure;
  width: 300px;
  text-align: center;
}

.mainLink {
  margin-top: 1rem;
}

.mainPic {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  animation: main-load 1200ms ease-in;
}


/*  */
.contactInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contactForm {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 450px;
}

.contactMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 5rem;

}



h1 {
  font-family: "Great Vibes", cursive;
}

/* nav bar size and styling */

/* Spacing and size of header */
.header {
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: rgba(238, 255, 254, 1);
  backdrop-filter: blur(5px);
  justify-content: space-between;
  display: flex;
  font-size: 1.7rem;
}

/* "about me" size and position */
.aboutMe {
  position: relative;
  padding-top: 5rem;
  padding-bottom: 10rem;
  background-color: rgba(238, 255, 254, 1);
}

.myInfo {
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  color: #677a8e;
  justify-content: center;
  margin-top: 1.5rem;
}

.myInfo img {
  justify-content: center;
}

.aboutContent {
  margin-top: 2rem;
  text-align: center;
  
}

.serviceItems {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  margin-bottom: 1rem;
}

.serviceItems img {
  object-fit: contain;
}

.serviceText {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.serviceBlocks {
  margin-bottom: 2rem;
}


.serviceBlocks li{
  text-align: center;
  margin-left: 4rem;
}



/* box child/text size for contacts section */
.contacts {
  text-align: center;
}

.serviceContact {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  text-align: center;
  justify-content: space-evenly;
}

footer {
  padding: 10px;
  background-color: rgba(238, 255, 254, 1);
}

/* box resize(flex) when page size changes or is used on device with different display size */
@media  (min-width: 992px) {
  .myInfo,  
  .contactMessage,
  .serviceContact,
  .serviceItems
   {
    height: auto;
    display: flex;
    flex-direction: row;
  }

  .navBar {
    justify-content: space-between;
  }  
  
  .mainName {
    padding: 6rem;
    font-size: 115px;
    line-height: 6rem;
  }
  
 

  .navBar img {
    height: 230px;
  }

  .welcome img {
    position: start absolute;
  }
  
  
  .welcome figCaption {
    font-size: 80px;
    position: absolute;
    text-align: center;
  }


  .mainServicePics {
    padding-top: 2rem;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  .mainServicePics figcaption {
    position: absolute;
    justify-content: center;
    padding-top: 85%;
  }

  .serviceBlocks {
    width: 550px;
  }

  .serviceBlocks li {
    text-align: start;
    margin-left: 8rem;
  }
}

@media  (max-width: 768px) {
  main,
  .myInfo,
  .serviceText{
    flex-wrap: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .serviceItems {
    flex-wrap: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: inherit;
  }

  .serviceBlocks li{
    width: 340px;
    text-align: start;
    margin-left: 2rem;
    justify-content: center;
  }

  .contactMessage,
  .contactForm,
  .message {
    flex-wrap: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mainServicePics {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
}

/* .container {
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2); 
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
} */